import { IGenuinePart } from '@/types/shared/parts';

const getMonth = (month) => {
  switch (month) {
    case 0:
      return 'ม.ค.';
    case 1:
      return 'ก.พ.';
    case 2:
      return 'มี.ค.';
    case 3:
      return 'เม.ย.';
    case 4:
      return 'พ.ค.';
    case 5:
      return 'มิ.ย.';
    case 6:
      return 'ก.ค.';
    case 7:
      return 'ส.ค.';
    case 8:
      return 'ก.ย.';
    case 9:
      return 'ต.ค.';
    case 10:
      return 'พ.ย.';
    case 11:
      return 'ธ.ค.';
  }
};

const getMonthFull = (month) => {
  switch (month) {
    case 0:
      return 'มกราคม';
    case 1:
      return 'กุมภาพันธ์';
    case 2:
      return 'มีนาคม';
    case 3:
      return 'เมษายน';
    case 4:
      return 'พฤษภาคม';
    case 5:
      return 'มิถุนายน';
    case 6:
      return 'กรกฎาคม';
    case 7:
      return 'สิงหาคม';
    case 8:
      return 'กันยายน';
    case 9:
      return 'ตุลาคม';
    case 10:
      return 'พฤศจิกายน';
    case 11:
      return 'ธันวาคม';
  }
};

export const formatDate = (date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonth(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatDateFull = (date) => {
  const _date = new Date(date);

  return `${_date.getUTCDate()} ${getMonthFull(_date.getUTCMonth())} ${
    _date.getUTCFullYear() + 543
  }`;
};

export const formatTel = (tel, stripDash = false) => {
  let formatTel = tel;
  if (tel[0] !== '0') {
    formatTel = `0${tel}`;
  }

  if (stripDash) {
    formatTel = formatTel.split('-')[0];
  }

  return formatTel;
};

export const youtubeURL = (id) => {
  return `https://www.youtube.com/embed/${id}/?autoplay=1&rel=0&modestbranding=1`;
};

export const removeSpan = (text) => {
  return text.replace(/<\/?span[^>]*>/g, '');
};

export const removeP = (text) => {
  return text.replace(/<\/?p[^>]*>/g, '');
};

export const removeHTMLTag = (text = '') => {
  return text.replace(/<\/?[^>]+(>|$)/g, '');
};

export const formatPrice = (val, unit = true) => {
  const num: number = +val;
  return (
    (unit ? 'USD ' : '') +
    num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  );
};

export const removeHash = (text: string) => {
  return text.replace('#', '');
};

export const camelCase = (text: string) => {
  return text.replace(/-([a-z])/g, (g) => {
    return g[1].toUpperCase();
  });
};

export const removeAsterisk = (text: string) => {
  return text.replace(/[*]/g, '');
};

export const formatNumber = (val, decimal = 0) => {
  return Number(val).toFixed(decimal);
};

export const cleanUrl = (val) => {
  return val.replace('//', '/');
};

export const removeDuplicateDot = (value) => {
  while (value.split('.').length > 2) {
    const lastIndex = value.lastIndexOf('.');
    value = `${value.substring(0, lastIndex)}${value.substring(lastIndex + 1)}`;
  }
  return value;
};

export const limitFloat = (value, decimalNumber) => {
  if (
    value.split('.').length === 2 &&
    value.split('.')[1].length > decimalNumber
  ) {
    let floatValue = value.split('.')[1];
    floatValue = floatValue.substr(0, decimalNumber);
    value = `${value.split('.')[0]}.${floatValue}`;
  }
  return value;
};

export const getPartPrefix = (part: IGenuinePart) => {
  let prefix = part.isIsuzuGenuinePart
    ? 'อะไหล่แท้<span>อีซูซุ</span>'
    : part.isTripetchGenuinePart
    ? 'อะไหล่แท้<span>ตรีเพชร</span>'
    : part.title && part.title.length > 0
    ? part.title
    : '';

  prefix = part.isStartPrice ? `${prefix}` : prefix;

  return prefix;
};
