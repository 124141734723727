import { ImageProps } from '@/components/shared/media/image/Image.props';

interface IData {
  price: number;
  label: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

export const V_CROSS_DATA: IData = {
  price: 33400,
  label: 'ວີ-ຄຣອສ 4x4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt671b3e6508f48789/6785e119bf0f68738e69fb42/laos-my25-v-cross.png',
    alt: 'v-cross 4x4',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfb6bb3cbb315f1b8/laos-catalog-v-cross-2025.pdf',
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 36900,
  label: 'ດີແມັກ 4 ປະຕູ',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta51ed2a1ba666cd1/67932801e8d2d16219754fcc/laos-my25-4-door.png',
    alt: 'pickup 4-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt13d7bbe07a98b6ea/laos-catalog-4door-2025.pdf',
};

export const SPARK_DATA: IData = {
  price: 26000,
  label: 'ສະປ໊າກ',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5ddebdd4e98a9dcd/6785e11926c6b478453f02ba/laos-my25-spark.png',
    alt: 'spark',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt921729e55531cf64/laos-catalog-spark-2025.pdf',
};

export const MUX_DATA: IData = {
  price: 59900,
  label: 'ມິວ-ເອັກ',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2d91f0105faf638c/6785e1191cd05fa5ce4b19dd/laos-my25-mu-x.png',
    alt: 'mu-x',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltcb739a5138750f99/67974597bc829dae916b8115/laos-catalog-mu-x-2025.pdf',
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_MENU: IMenu = {
  label: MUX_DATA.label,
  description: `ເລີ່ມຕົ້ນ USD ${MUX_DATA.price.toLocaleString()}`,
  url: MUX_DATA.url,
  image: MUX_DATA.image,
  brochure: MUX_DATA.brochure,
  // FOR SHOW A BADGE
};
